.profile-sidebar {
  background-color: rgb(5, 20, 53);
  height: 100%; /* Ensures full height */
  color: white;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
}

.profile-content {
  min-height: 100vh; /* Matches sidebar height */
  padding: 20px;
}

.profile-sidebar .sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.profile-sidebar .sidebar-header img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 10px;
}

.profile-sidebar .sidebar-title {
  font-size: 16px;
  color: white;
  flex-grow: 1;
}

.profile-sidebar .sidebar-home-icon {
  cursor: pointer;
  color: white;
}

.profile-sidebar .sidebar-nav {
  margin-top: 20px;
}

.profile-sidebar .nav-link {
  color: #ffffff;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  margin: 3px 0;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.profile-sidebar .nav-link:hover,
.profile-sidebar .nav-link.active {
  background-color: #ffffff;
  color: #000000;
}

.profile-sidebar .sidebar-logout {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  margin-top: auto;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  div {
    padding: 10px;
  }
}

.profile-sidebar .sidebar-logout div:hover {
  background-color: #ffffff;
  color: #000000;

  border-radius: 8px;
}

.profile-sidebar .sidebar-logout .logout-icon {
  margin-right: 8px;
}

.sidebar-offcanvas {
  background-color: rgb(5, 20, 53) !important;
  color: white;
}

.sidebar-offcanvas .nav-link {
  color: #ffffff;
}

.sidebar-offcanvas .nav-link.active {
  color: white;
  font-weight: bold;
}

.offcanvas-header-custom {
  background: rgb(5, 20, 53);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.close-btn {
  cursor: pointer;
  color: white !important;
}

.sidebar-toggle {
  top: 15px;
  left: 15px;
  z-index: 1000;
  background: #000000;
  border-radius: 0;
  color: white;
}
@media screen and (max-width: 768px) {
  .profile-sidebar .sidebar-header {
    padding-bottom: 0;
  }
  .offcanvas-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
