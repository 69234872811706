.cardContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.cardContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.imageWrapper {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardImage {
  height: 165px;
  width: 115px;
  object-fit: cover;
  border-radius: 15px;
  transition: transform 0.3s ease, z-index 0.3s ease;
}

.cardImage:hover {
  transform: scale(1.5);
}

.cardBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

/* Ensure consistent height for text alignment */
.cardTitleWrapper,
.cardAuthorsWrapper,
.cardTextWrapper {
  display: flex;
  align-items: flex-start; /* Align all sections at the same start point */
  min-height: 40px; /* Ensures consistency even with different title lengths */
}

/* Individual Styling */
.cardTitle {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: flex-end;
}

.cardAuthors {
  font-size: 15px;
  color: #333;
  font-weight: 500;
  min-height: 35px;
  align-items: flex-start;
}

.cardText {
  font-size: 14px;
}

/* Link Styling */
.authorName {
  text-decoration: none;
  color: #051435;
  font-weight: 500;
}

.authorName:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .cardContent {
    display: flex;
    flex-direction: column;
  }

  .imageWrapper {
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #dddddd;
  }
}
