/* NaucniRadoiv.module.css */

/* Container for each paper card */
.paperCard {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  padding: 1rem;
  position: relative;
}

/* Top row: Title + external link icon */
.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

/* Title text */
.paperTitle {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3;
}

/* The "paper_url" icon in top-right corner */
.externalLinkIcon {
  color: #555;
  font-size: 16px;
  text-decoration: none;
  margin-left: 8px;
}
.externalLinkIcon:hover {
  color: #000;
}

/* Authors line, separated by bullets */
.authorsRow {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.authorLink {
  color: #051435;
  font-weight: 500;
  text-decoration: none;
}
.authorLink:hover {
  text-decoration: underline;
}

/* Journal name (if provided) */
.journalName {
  margin: 0.25rem 0;
  font-size: 14px;
  font-weight: 500;
}

/* Date + paper type line */
.dateTypeLine {
  margin: 0;
  font-size: 14px;
  margin-bottom: 0.5rem;
}

/* DOI line: "DOI: 10.XXXX" */
.doiLine {
  color: #212529BF;
  margin: 0;
  font-size: 14px;
}
.doiLine a {
  color: #212529BF;
  text-decoration: none;
}
.doiLine a:hover {
  text-decoration: underline;
}
