.backButton {
  color: #0094ff;
  font-size: 16px;
  cursor: pointer;
  align-items: center;
  margin-bottom: 40px;
  padding-bottom: 40px;
  margin-top: 40px;
  border-bottom: 1px solid #dddddd;
}

.contentWrapper {
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  height: min-content;
}

.borderBottom {
  border-bottom: 1px solid #dddddd;
}
.htmlWrapper {
  padding-left: 10px;
  overflow-y: auto; /* Allows scrolling if content exceeds the max height */
  max-height: 400px; /* Limit the height of the container */
  font-size: 14px; /* Make text smaller */
  line-height: 1.6; /* Adds some space between lines for better readability */
  word-wrap: break-word; /* Ensures long words break and wrap properly */
  word-break: break-word; /* Prevents long words from overflowing */
  overflow-wrap: break-word;
}

.allResearchesButton {
  font-size: 12px;
  cursor: pointer;
  color: #121518;
  border: 1px solid #121518;
  background: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 15px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.allResearchesButton:hover {
  background-color: #121518;
  color: #fff;
}

.tab-heading {
  font-size: 22px;
}