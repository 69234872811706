.booksRow {
  max-height: 600px;
  overflow: auto;
}

.bookCard {
  box-shadow: 0 0.425rem 0.525rem rgba(17, 17, 17, 0.2) !important;
  border: none;
}

.imageCol {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible; /* Ensure no clipping */
  position: relative; /* Required for absolute positioning of zoomed image */
}

.bookImage {
  height: 130px;
  width: 100px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 10px;
  transition: transform 0.3s ease, z-index 0.3s ease;
}

.bookImage:hover {
  transform: scale(1.5);
  z-index: 10; /* Bring it above other elements */
}

.bookDetailsCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bookCardBody {
  padding-top: 0;
  padding-bottom: 0;
}

.bookTitle {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.authorName {
  color: #333;
  font-weight: 500;
  font-size: 15px;
}

.authorLink {
  text-decoration: none !important;
  color: #051435 !important;
  font-weight: 500;
}

.authorLink:hover {
  text-decoration: underline !important;
}

@media (max-width: 768px) {
  .card_container {
    display: flex;
    flex-direction: column;
  }

  .imageCol {
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #dddddd;
  }
}
