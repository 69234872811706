.radoviContainer {
  max-height: 600px;
  overflow: auto;
}
.paperCard {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  padding: 1rem;
  position: relative;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paperTitle {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
}

.externalLinkIcon {
  color: #555;
  font-size: 16px;
  text-decoration: none;
  margin-left: 8px;
}

.authorsRow {
  margin-top: 0.5rem;
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

.authorLink {
  color: #051435;
  font-weight: 500;
  text-decoration: none;
}

.authorLink:hover {
  text-decoration: underline;
}

.journalName {
  margin: 0.5rem 0 0;
  font-size: 14px;
  font-weight: 500;
}

.dateTypeLine {
  margin: 0.25rem 0;
  font-size: 14px;
}

.doiLine {
  color: #212529BF;
  margin: 0.25rem 0;
  font-size: 14px;
}

.doiLine a {
  color: #212529BF;
  text-decoration: none;
}
.doiLine a:hover {
  text-decoration: underline;
}
