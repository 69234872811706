/* Table container */
.knjige-table {
  border-radius: 10px;
  overflow: hidden;
}

/* Table header */
.knjige-table th {
  background-color: rgb(5, 20, 53); /* Blue header background */
  color: white;
  text-align: center;
  vertical-align: middle; /* Bootstrap default */
  font-weight: bold;
  padding: 12px;
  border: none;
}

.knjige-table .subject {
  text-align: center;
  font-weight: bold;
  vertical-align: middle; /* Bootstrap default */
}

.knjige-table .book-title,
.knjige-table .book-th-title {
  font-weight: bold;
  text-align: left;
  padding-left: 70px;
}

.knjige-table .book-title {
  font-size: 16px;
}

/* Table body */
.knjige-table td {
  text-align: center;
  vertical-align: middle; /* Bootstrap default */
  padding: 10px;
  border: none;
}

/* Icon hover effect */
.knjige-table .text-center a:hover svg {
  color: #007bff;
}

/* Action buttons container */
.knjige-table .d-flex.align-center {
  display: flex;
  align-items: center;
  gap: 8px;
}

.knjige-table img {
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease, z-index 0.3s ease;

}

.knjige-table .th-image,
.knjige-table .td-image {
  padding-left: 40px; /* Adjust as needed */
}

.knjige-table img:hover {
  transform: scale(1.5);
}


/* Action buttons */
.knjige-table button {
  transition: all 0.3s ease;
}

.knjige-table button:hover {
  opacity: 0.8;
}

/* Search input container */
.knjige-search {
  max-width: 400px;
  margin-bottom: 20px;
}

/* Spinner */
.knjige-table .spinner-border {
  color: #007bff;
}

/* Modal */
.knjige-modal h5 {
  font-size: 18px;
  font-weight: 600;
}

.knjige-footer {
  justify-content: center;
}


@media (max-width: 768px) {
  .knjige-table {
    min-width: 600px;
  }

  .knjige-table tr {
    min-height: 120px;
  }

  .knjige-table th,
  .knjige-table td {
    padding: 5px;
    font-size: 14px;
    min-height: 100px;
  }

  .knjige-table td.icons {
    text-align: center;
    vertical-align: middle;
    min-width: 150px;
  }

  .knjige-table td:nth-child(2) {
    display: flex;
    align-items: center;
    padding-left: 25px;
    text-align: left;
    vertical-align: middle;
  }
  
  .knjige-table td:nth-child(2) .clamp-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    font-size: 14px;
  }

  
}

@media (max-width: 400px) {
  .user-panel-knjige-table-btns  {
    flex-direction: column;
    align-items: center;
  }
  
  .user-panel-knjige-table-btns > * {
    width: 90%;
    margin-bottom: 10px !important;
    margin-right: 0 !important;
    font-size: 14px;
  }
  
  .user-panel-knjige-table-btns > *:last-child {
    margin-bottom: 0 !important;
  }
}