.navbar__title {
  font-size: 15px;
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
}
.swagger__link {
  color: #85ea2d !important;
}

.dropdown-toggle::after {
  display: none !important;
}
.bi-chevron-down {
  font-size: 1em; /* Adjust size as needed */
  vertical-align: middle;
}

.homepage__navbar {
  backdrop-filter: "blur(32px)";
  background-color: transparent;
}

.other__page {
  background-color: "#051435" !important;
}

.style__icon {
  width: 48px;
  height: 48px;
  padding: 14px 14px 13px 14px;
  border-radius: 360px;
  background-color: #192849;
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
}
.user_background {
  background-color: transparent !important;
  color: black;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
}
.project_wrapper {
  width: 100%;
  display: flex;
}

.navbar__subsection_title {
  color: #a9a9a9;
  font-size: 12px;
}
a.nav-link {
  margin-right: 10px !important;
}

.user_image {
  width: 100%;
  height: 100%;
  border: 2px solid #ffffff;
  border-radius: 50%;
}
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
    left: -75px !important;
    width: 850px !important;
    background-color: #051435 !important;
    padding: 24px !important;
    top: 40px;
    .dropdown__items_wrapper {
      display: flex;
      width: "100%";
    }
    a {
      color: #ffffff !important;
      width: 32.2% !important;
      display: flex;
      margin-bottom: 15px;
      align-items: center;
      padding: 0;
      word-wrap: break-word;
      white-space: normal;
      font-size: 14px;

      .svg__wrapper {
        width: 40px;
        height: 40px;
        display: block !important;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #192849;
        padding: 10px;
        margin-right: 10px;
        svg {
          width: 100%;
          height: 100%;
          flex-shrink: 0 !important;
        }
      }
    }

    a:hover {
      color: lightblue !important;
      background-color: transparent !important;
    }
    a:focus {
      background-color: transparent !important;
    }
    .user_menu {
      min-width: min-content !important;
      left: -5px !important;
    }
  }

  .navbar-nav {
    position: relative !important;
  }
  .nav-item {
    position: static !important;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu.show {
    display: flex;
    flex-wrap: wrap;
  }

  .user_dropdown {
    width: 48px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .dropdown-menu.show {
      display: block !important;
      /* width: 100px !important; */
      width: min-content !important;
      left: -5px !important;
      min-width: min-content !important;
      top: 50px;
    }
  }
}

@media (max-width: 1199px) {
  #basic-navbar-nav {
    height: 100vh !important;
    padding: 24px !important;
    overflow-y: scroll !important;
    /* background-color: #051435 !important; */
    a.nav-link {
      color: #ffffff !important;
      font-size: 14px !important;
      display: flex !important;
      justify-content: space-between !important;
      padding: 12px !important;
      span {
        width: 100% !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
      }
    }

    a.dropdown-item {
      color: #ffffff !important;
      padding-left: 0 !important;
      display: flex !important;
      align-items: center;
      font-size: 14px !important;
      margin: 10px 0;
      text-wrap: auto;
      .dropdown__items_wrapper {
        display: flex;
        flex-direction: column;
      }

      .svg__wrapper {
        width: 40px;
        height: 40px;
        display: block !important;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #192849;
        padding: 10px;
        margin-right: 10px;
        svg {
          width: 100%;
          height: 100%;
          flex-shrink: 0 !important;
        }
      }
    }
    a.dropdown-item:focus {
      background-color: transparent !important;
    }
    a.dropdown-item:hover {
      background-color: transparent !important;
    }

    a.dropdown-item:active {
      background-color: transparent !important;
    }

    .navbar__icon_wrapper {
      display: flex;
      flex-direction: row;
      margin: 10px 0;
    }
    .dropdown-menu.show {
      background-color: transparent !important;
      border: none !important;
      max-height: 420px;
      overflow-y: auto;
    }
    .dropdown-toggle.show.nav-link {
      background-color: #2d3c5d;
    }
    .nav__icons {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
    }
  }
  .navbar__title {
    font-size: 14px;
  }
  .project_wrapper {
    flex-direction: column;
  }
}
