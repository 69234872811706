/* Table container */
.projekti-table {
  border-radius: 10px;
  overflow: hidden;
  table-layout: fixed;
}

/* Table header */
.projekti-table th {
  background-color: rgb(5, 20, 53);
  color: white;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  padding: 12px;
  border: none;
}

/* Project title columns */
.projekti-table .project-th-title {
  font-weight: bold;
  text-align: left;
}

.projekti-table .project-title {
  font-size: 16px;
  text-align: left;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
}

/* Table body */
.projekti-table td {
  text-align: center;
  vertical-align: middle;
  padding: 16px;
  border: none;
}

/* Action buttons container */
.projekti-table .d-flex.align-center {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Action buttons */
.projekti-table button {
  transition: all 0.3s ease;
}

.projekti-table button:hover {
  opacity: 0.8;
}

/* Search input container */
.projekti-search {
  max-width: 400px;
  margin-bottom: 20px;
}

/* Spinner */
.projekti-table .spinner-border {
  color: #007bff;
}

/* Modal */
.projekti-modal h5 {
  font-size: 18px;
  font-weight: 600;
}

.projekti-footer {
  justify-content: center;
}
@media (min-width: 768px) {
  .projekti-table thead th:nth-child(1),
  .projekti-table tbody td:nth-child(1) {
    padding-left: 2%;
    width: 50%;
  }

  .projekti-table thead th:nth-child(2),
  .projekti-table tbody td:nth-child(2) {
    width: 15%;
  }

  .projekti-table thead th:nth-child(3),
  .projekti-table tbody td:nth-child(3) {
    width: 15%;
  }

  .projekti-table thead th:nth-child(4),
  .projekti-table tbody td:nth-child(4) {
    width: 10%;
  }
}

@media (max-width: 768px) {
  .projekti-table {
    min-width: 600px;
  }
  .projekti-table th,
  .projekti-table td {
    padding: 8px 5px; /* Increased vertical padding */
    font-size: 14px;
    height: 5.3em; /* Fixed height for 3 lines (1.5em line-height × 3) */
    line-height: 0.5em; /* Consistent line spacing */
    box-sizing: border-box; /* Include padding in height calculation */
    vertical-align: middle; /* Center content vertically */
    overflow: hidden; /* Hide overflow */
  }

  .projekti-table td:nth-child(4) {
    text-align: center;
    vertical-align: middle;
  }

  .projekti-table tbody td:nth-child(1) {
    font-size: 14px;
    line-height: 1.5em;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media (max-width: 400px) {
  .user-panel-projekti-table-btns {
    flex-direction: column;
    align-items: center;
  }
  
  .user-panel-projekti-table-btns  > * {
    width: 90%;
    margin-bottom: 10px !important;
    margin-right: 0 !important;
    font-size: 14px;
  }
  
  .user-panel-projekti-table-btns  > *:last-child {
    margin-bottom: 0 !important;
  }
}
