.sectionTitle {
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.sectionSubtitle {
  font-size: 16px;
}

.viewAllLink {
  color: #051435;
  font-weight: 500;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 4px;
}

.viewAllLink:hover {
  text-decoration: underline;
}

.paperCard {
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  padding: 2rem 1rem;
  height: 180px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.externalLink {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: #444;
  font-size: 18px;
  text-decoration: none;
}

.externalLink:hover {
  color: #000;
}

.paperTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  min-height: 60px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: box; /* Older spec fallback (not widely supported) */
  line-clamp: 3; /* Standard property (for the future) */
  box-orient: vertical; /* Fallback for non-WebKit browsers */
}

.authors {
  margin: 0;
  font-size: 13px;
  color: #666;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: box; /* Older spec fallback (not widely supported) */
  line-clamp: 3; /* Standard property (for the future) */
  box-orient: vertical; /* Fallback for non-WebKit browsers */
}

.dateLine {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: rgba(159, 218, 248, 1);
  color: #000;
  padding: 0.25rem 0.5rem;
  font-size: 12px;
  border-radius: 5px;
  margin: 0;
}
.authorLink {
  color: #051435;
  font-weight: 500;
  text-decoration: none;
}
.authorLink:hover {
  text-decoration: underline;
}

.hidden {
  display: none;
}

.arrowStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  color: grey;
  cursor: pointer;
  position: absolute;
  border: 1px solid #dddddd;
  z-index: 2;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.hidden {
  display: none;
}

.nextArrow {
  right: -20px;
  top: 40%;
}

.prevArrow {
  left: -20px;
  top: 40%;
}
