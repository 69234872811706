.radovi-table {
  width: 100%;
  table-layout: fixed;
  border-radius: 10px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .radovi-table thead th:nth-child(1),
  .radovi-table tbody td:nth-child(1) {
    padding-left: 2%;
    width: 60%;
  }

  .radovi-table thead th:nth-child(2),
  .radovi-table tbody td:nth-child(2) {
    width: 15%;
  }

  .radovi-table thead th:nth-child(3),
  .radovi-table tbody td:nth-child(3) {
    width: 10%;
  }

  .radovi-table thead th:nth-child(4),
  .radovi-table tbody td:nth-child(4) {
    width: 10%;
  }
}

/* Table header */
.radovi-table th {
  background-color: rgb(5, 20, 53);
  color: white;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  padding: 12px;
  border: none;
}

/* Some generic classes used in the table */
.radovi-table .subject {
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
}

/* Title columns – clamp to 2 lines */
.radovi-table .paper-title,
.radovi-table .paper-th-title {
  font-weight: bold;
  text-align: left;
}

.radovi-table td {
  text-align: center;
  vertical-align: middle;
  padding: 16px;
  border: none;
}

.radovi-table .d-flex.align-center {
  display: flex;
  align-items: center;
  gap: 8px;
}

.radovi-table img {
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease, z-index 0.3s ease;
}

.radovi-table img:hover {
  transform: scale(1.5);
}

.radovi-table button {
  transition: all 0.3s ease;
}

.radovi-table button:hover {
  opacity: 0.8;
}

.radovi-search {
  max-width: 400px;
  margin-bottom: 20px;
}

.radovi-table .spinner-border {
  color: #007bff;
}

.radovi-modal h5 {
  font-size: 18px;
  font-weight: 600;
}

/* Modal footer alignment */
.radovi-footer {
  justify-content: center;
}

/* Link icon color */
.radovi-table .external-link-icon {
  color: #555;
}

.radovi-table .external-link-icon:hover {
  color: #007bff;
}

@media (max-width: 768px) {
  .radovi-table {
    min-width: 600px;
  }
  
  .radovi-table th,
  .radovi-table td {
    padding: 8px 5px; /* Increased vertical padding */
    font-size: 14px;
    height: 5.3em; /* Fixed height for 3 lines (1.5em line-height × 3) */
    line-height: 0.5em; /* Consistent line spacing */
    box-sizing: border-box; /* Include padding in height calculation */
    vertical-align: middle; /* Center content vertically */
    overflow: hidden; /* Hide overflow */
  }

  /* Specific alignment for column 4 */
  .radovi-table td:nth-child(4) {
    text-align: center;
  }

  /* Title column styling */
  .radovi-table tbody td:nth-child(1) {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    text-align: left;
    max-height: 4.5em; /* Match other cells */
  }

  /* Paper title styling */
  .radovi-table .paper-title {
    font-size: 14px;
    line-height: 1.5em;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}


@media (max-width: 400px) {
  .user-panel-radovi-table-btns {
    flex-direction: column;
    align-items: center;
  }
  
  .user-panel-radovi-table-btns  > * {
    width: 90%;
    margin-bottom: 10px !important;
    margin-right: 0 !important;
    font-size: 14px;
  }
  
  .user-panel-radovi-table-btns  > *:last-child {
    margin-bottom: 0 !important;
  }
}